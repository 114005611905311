.Button {
  display: block;
  background-color: var(--color-green);
  text-transform: uppercase;
  width: 100%;
  color: var(--color-black);
  padding: 8px 10px;
  border-radius: 4px;
  border: none;
  margin: 30px 0;
  font-weight: bold;
}

.Button:disabled {
  background-color: var(--color-gray);
  color: var(--color-gray-text);
}
