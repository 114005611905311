.GameScoreScreen {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 40px 0;
}

.Heading {
  flex-shrink: 0;
}

.Heading img {
  display: block;
}

.text-bold {
  font-weight: bold;
}

.BrandWrapper {
  padding: 20px 0;
}

.info-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 14px 24px;
}

.info-wrapper.user {
  background-color: #8dc63f;
}

.info-wrapper.user .prop {
  color: #000000;
}

.info-wrapper.scores {
  background-color: #00964b;
  color: #ffffff;
  font-weight: bold;
}

.FormWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
}

.score-inputs {
  margin-top: 20px;
}

.select-box {
  width: 120px;
}
