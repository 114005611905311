.SelectFieldWrapper {
  position: relative;
}

/* Remove default styling */
.SelectFieldWrapper select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.SelectFieldWrapper select {
  background-color: var(--color-black);
  border: 1px solid var(--color-green);
  border-radius: 6px;
  padding: 8px 6px;
  font-weight: 500;
  width: 100%;
  position: relative;
}

/* .SelectFieldWrapper select.error {
  border: 1px solid var(--color-red);
} */

.SelectFieldWrapper::before {
  content: '';
  width: 12px;
  height: 12px;
  top: calc(50% - 4px);
  right: 10px;
  background-color: var(--color-white);
  clip-path: polygon(100% 0%, 0 0%, 50% 60%);
  position: absolute;
  z-index: 1000;
}
