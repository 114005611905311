.UserInfoContainer {
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
}

.UserFormTitle {
  text-transform: capitalize;
  padding: 0 10px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.DOBWrapper {
  display: flex;
  margin: 0 -10px;
}

.DOBWrapper .item {
  flex-grow: 1;
  padding: 0 10px;
}

.dobErrorMessage {
  color: var(--color-green);
  font-size: 14px;
  margin-top: 4px;
}
