.TextFieldWrapper {
  margin: 10px 0;
}

.TextFieldLabel {
  display: block;
  margin-bottom: 4px;
}

.TextFieldInput {
  width: 100%;
  padding: 8px 6px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid var(--color-green);
  background-color: var(--color-black);
}

.TextFieldWrapper .errorMessage {
  color: var(--color-green);
  font-size: 14px;
  margin-top: 4px;
}

/* .TextFieldInput.error {
  border: 1px solid var(--color-red);
} */
