.CompleteContainer {
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;
}

.CompleteInfo {
  padding: 80px 0;
  text-align: center;
}

.CompleteTitle {
  text-transform: capitalize;
  font-size: 26px;
  margin-bottom: 10px;
}

.CompleteSubtitle {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: bold;
}

.TotalPoints {
  font-size: 60px;
  color: var(--color-green);
  font-weight: bold;
  margin-bottom: 10px;
}

.CompleteNote {
  color: var(--color-green);
  opacity: 0.8;
}
