.CheckboxWrapper {
  margin: 16px 0;
}

.Checkbox[type='checkbox']:checked::after {
  content: '';
  width: 12px;
  height: 12px;
  display: block;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 3px;
}

.Checkbox[type='checkbox'] {
  text-align: center;
  vertical-align: middle;
  position: relative;
  top: -3px;
  width: 18px !important;
  height: 18px !important;
  border-radius: 50%;
  margin-right: 10px;
  background: url('../../../../public/un-check.png'), var(--color-black);
  background-size: 18px;
  border: none;
  appearance: none;
}

.Checkbox[type='checkbox']:checked {
  background: url('../../../../public/check.png'), var(--color-black);
  background-size: 18px;
}
