.BrandWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.BrandWrapper img {
  width: 360px;
}
