:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray: #3e3e3e;
  --color-gray-text: #5a5a5a;
  --color-green: #8ec63e;
  --color-red: #ff3333;
}

@font-face {
  font-family: 'Avenir';
  src: url('../public/fonts/Avenir.ttc');
}

* {
  margin: 0;
  padding: 0;
  color: #ffffff;
  font-family: Avenir;
  user-select: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus,
select:focus {
  outline: none;
}

img {
  width: 100%;
}
